body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.7em;
}

h1,h2,h3,h4,h5 {
  margin-top: 8px;
  margin-bottom: 16px;
}

p {
  color: #313539;
  margin: 16px 0;
}
