.App-logo {
  pointer-events: none;
  width: 600px;
  max-width: 80vw;
}

header {
  background-color: #124f6e;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.separator-padding {
  padding: 16px 0;
}

.separator {
  margin: 0 auto;
  height: 1px;
}